import React, { useEffect, useState } from "react";
import { collection, getDocs, query, orderBy, limit } from "firebase/firestore";
import { db } from "../firebase-config";
import { Link } from "react-router-dom";
import "./CSS/Home.css";

const Home = () => {
  const [latestPost, setLatestPost] = useState(null);
  const [nextEvent, setNextEvent] = useState(null);

  useEffect(() => {
    const fetchLatestPost = async () => {
      const postsCollectionRef = collection(db, "posts");
      const q = query(postsCollectionRef, orderBy("timestamp", "desc"), limit(1));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        setLatestPost(querySnapshot.docs[0].data());
      }
    };

    const fetchNextEvent = async () => {
      const eventsCollectionRef = collection(db, "events");
      const q = query(eventsCollectionRef, orderBy("date", "asc"), limit(1));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        setNextEvent(querySnapshot.docs[0].data());
      }
    };

    fetchLatestPost();
    fetchNextEvent();
  }, []);

  const extractText = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  const getPreviewText = (text, lines) => {
    const lineArray = text.split("\n").slice(0, lines);
    return lineArray.join(" ");
  };

  return (
    <div className="homeContainer">
    
      <div className="nextEvent">
        <h2>Nächster Auftritt</h2>
        {nextEvent ? (
          <div>
            <h3>{nextEvent.name}</h3>
            <p>{new Date(nextEvent.date).toLocaleDateString()} um {nextEvent.time} Uhr</p>
            <p>{nextEvent.location}</p>
          </div>
        ) : (
          <p>Lädt...</p>
        )}
      </div>

      <div className="latestPost">
        <h2>Aktuelles</h2>
        {latestPost ? (
          <div className="postPreview">
            <h3>{latestPost.title}</h3>
            <p>{new Date(latestPost.timestamp.seconds * 1000).toLocaleDateString()}</p>
            {latestPost.imageUrls && latestPost.imageUrls.length > 0 && (
              <img src={latestPost.imageUrls[0]} alt="Post preview" className="postImagePreview" />
            )}
            <p className="postTextPreview">
              {getPreviewText(extractText(latestPost.postText), 3)}...
              <Link to="/aktuelles">Mehr</Link>
            </p>
          </div>
        ) : (
          <p>Lädt...</p>
        )}
      </div>
    </div>
    
    
  );
};

export default Home;
