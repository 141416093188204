import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Aktuelles from "./pages/Aktuelles";  // Geändert von News zu Aktuelles
import CreatePost from "./pages/CreatePost";
import Login from "./pages/Login";
import ÜberUns from "./pages/ÜberUns";
import JiriMares from "./pages/JiriMares";
import Musiker from "./pages/Musiker";
import Satzung from "./pages/Satzung";
import Anfahrt from "./pages/Anfahrt";
import Proben from "./pages/Proben";
import { useState } from "react";
import { signOut } from "firebase/auth";
import { auth } from "./firebase-config";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import CalendarView from "./pages/CalendarView";
import CreateEvent from "./pages/CreateEvent";
import loginIcon from "./Images/login-icon.png";
import homeIcon from "./Images/home-icon.png";
import Impressum from "./pages/Impressum";
import Newsletter from "./pages/Newsletter";

function App() {
  const [isAuth, setIsAuth] = useState(localStorage.getItem("isAuth"));
  const [menuOpen, setMenuOpen] = useState(false);

  const signUserOut = () => {
    signOut(auth).then(() => {
      localStorage.clear();
      setIsAuth(false);
      window.location.pathname = "/login";
    });
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Router>
      <div className="App">
        <nav className="navbar">
          <div className="navbar-brand">
            <button onClick={toggleMenu} className="menu-toggle">
              &#9776;
            </button>
            <Link to="/">
              <img src={homeIcon} alt="Home" className="home-icon" />
            </Link>
          </div>
          <div className={`navbar-links ${menuOpen ? "active" : ""}`}>
            <div className="dropdown">
              <button className="dropbtn">Neuigkeiten</button>
              <div className="dropdown-content">
                <Link to="/aktuelles"> Aktuelles </Link>
                <Link to="/calendar"> Kalender </Link>
              </div>
            </div>
            <div className="dropdown">
              <button className="dropbtn">Der Verein</button>
              <div className="dropdown-content">
                <Link to="/über-uns"> Über Uns </Link>
                <Link to="/jiri-mares"> Jiri Mares </Link>
                <Link to="/musiker"> Musiker </Link>
                <Link to="/satzung"> Satzung </Link>
                <Link to="/impressum"> Impressum </Link>
              </div>
            </div>
            <div className="dropdown">
              <button className="dropbtn">Mitmachen</button>
              <div className="dropdown-content">
                <Link to="/anfahrt"> Anfahrt </Link>
                <Link to="/proben"> Proben </Link>
              </div>
            </div>
            {isAuth && (
              <div className="dropdown">
                <button className="dropbtn">Inhalte erstellen</button>
                <div className="dropdown-content">
                  <Link to="/createpost"> Beitrag erstellen </Link>
                  <Link to="/createevent"> Auftritt erstellen </Link>
                </div>
              </div>
            )}
          </div>
          <div className="navbar-login">
            {!isAuth ? (
              <Link to="/login">
                <div class = "login-container">
                <img src={loginIcon} alt="Login" className="login-icon" />
                <span>Login</span>
                </div>
                
              </Link>
            ) : (
              <div className="dropdown profile-dropdown">
                <button className="dropbtn">
                  <img src={loginIcon} alt="Profile" className="login-icon" />
                  {auth.currentUser && <span className="username">{auth.currentUser.displayName}</span>}
                </button>
                <div className="dropdown-content">
                  <Link to="/profile">Profile</Link>
                  <button onClick={signUserOut} className="logout-btn">Log Out</button>
                </div>
              </div>
            )}
          </div>
        </nav>
        <div className="scroll-container">
          <div className="content">
            <Routes>
              <Route path="/" element={<Home isAuth={isAuth} />} />
              <Route path="/aktuelles" element={<Aktuelles isAuth={isAuth} />} />
              <Route path="/calendar" element={<CalendarView />} />
              <Route path="/createpost" element={<CreatePost isAuth={isAuth} />} />
              <Route path="/createevent" element={<CreateEvent isAuth={isAuth} />} />
              <Route path="/edit/:id" element={<CreatePost isAuth={isAuth} />} />
              <Route path="/login" element={<Login setIsAuth={setIsAuth} />} />
              <Route path="/über-uns" element={<ÜberUns />} />
              <Route path="/jiri-mares" element={<JiriMares />} />
              <Route path="/musiker" element={<Musiker />} />
              <Route path="/satzung" element={<Satzung />} />
              <Route path="/anfahrt" element={<Anfahrt />} />
              <Route path="/proben" element={<Proben />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/impressum" element={<Impressum />} />
              <Route path="/newsletter" element={<Newsletter />} />
            </Routes>
          </div>
          <footer className="footer">
            <p>&copy; {new Date().getFullYear()} Bigband Jiri Mares e.V.</p>
            <Link to="/impressum">Impressum</Link>
          </footer>
        </div>
      </div>
    </Router>
  );
}

export default App;
