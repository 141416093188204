import React, { useState } from "react";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase-config";
import { useNavigate } from "react-router-dom";
import "./CSS/CreateEvent.css";

const CreateEvent = () => {
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState(""); // Neues State für die Uhrzeit
  const [location, setLocation] = useState("");
  let navigate = useNavigate();

  const createEvent = async () => {
    await addDoc(collection(db, "events"), {
      name,
      date,
      time, // Uhrzeit zum Event hinzufügen
      location,
    });
    navigate("/calendar");
  };

  return (
    <div className="createEventPage">
      <div className="cpContainer">
        <h1>Erstelle einen neuen Termin</h1>
        <div className="inputGp">
          <label>Name:</label>
          <input
            type="text"
            placeholder="Name..."
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="inputGp">
          <label>Datum:</label>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
        <div className="inputGp">
          <label>Uhrzeit:</label> {/* Neues Eingabefeld für die Uhrzeit */}
          <input
            type="time"
            value={time}
            onChange={(e) => setTime(e.target.value)}
          />
        </div>
        <div className="inputGp">
          <label>Ort:</label>
          <input
            type="text"
            placeholder="Ort..."
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </div>
        <button onClick={createEvent}>Speichern</button>
      </div>
    </div>
  );
};

export default CreateEvent;
