import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase-config';
import './CSS/CalendarView.css';

const CalendarView = () => {
  const [events, setEvents] = useState([]);
  const [date, setDate] = useState(new Date());
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showTable, setShowTable] = useState(false);

  useEffect(() => {
    const fetchEvents = async () => {
      const eventsCollection = collection(db, 'events');
      const eventsSnapshot = await getDocs(eventsCollection);
      const eventsList = eventsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      const filteredEvents = eventsList.filter(event => new Date(event.date) >= new Date().setHours(0, 0, 0, 0));
      setEvents(filteredEvents);
    };

    fetchEvents();
  }, []);

  return (
    <div className="calendarPage">
      <div className="eventsTable">
        <h2>Unsere nächsten Termine</h2>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Datum</th>
              <th>Uhrzeit</th>
              <th>Ort</th>
            </tr>
          </thead>
          <tbody>
            {events.map(event => (
              <tr key={event.id}>
                <td>{event.name}</td>
                <td>{new Date(event.date).toLocaleDateString()}</td>
                <td>{event.time}</td>
                <td>{event.location}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CalendarView;
